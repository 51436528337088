.swiper-container {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;

    background: #fff;

    /* Center slide text vertically */

    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -webkit-box-pack: center;
    -ms-flex-pack: center;

    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.swiper-pagination-bullet {
    width: 13px;
    height: 13px;
    text-align: center;
    line-height: 20px;
    font-size: 12px;
    color: #000;
    opacity: 1;
    background: rgba(255, 255, 255, 0.2);
    display: inline-block;
}

.swiper-pagination-bullet-active {
    background: #fff;
}

.swiper-pagination {
    width: auto !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
    bottom: 5% !important;
}
