/**
 * Avenir Next W01
 */
 @font-face {
    font-family: 'Avenir Next W01';
    src: url('./assets/fonts/avenir-next-lt-w01-light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Avenir Next W01';
    src: url('./assets/fonts/avenir-next-lt-w01-light-italic.woff2') format('woff2');
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: 'Avenir Next W01';
    src: url('./assets/fonts/avenir-next-lt-w01-regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Avenir Next W01';
    src: url('./assets/fonts/avenir-next-lt-w01-regular-italic.woff2') format('woff2');
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: 'Avenir Next W01';
    src: url('./assets/fonts/avenir-next-lt-w01-medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Avenir Next W01';
    src: url('./assets/fonts/avenir-next-lt-w01-medium-italic.woff2') format('woff2');
    font-weight: 500;
    font-style: italic;
}
@font-face {
    font-family: 'Avenir Next W01';
    src: url('./assets/fonts/avenir-next-lt-w01-demi.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Avenir Next W01';
    src: url('./assets/fonts/avenir-next-lt-w01-demi-italic.woff2') format('woff2');
    font-weight: 700;
    font-style: italic;
}
/**
 * Corporate
 */
@font-face {
    font-family: 'Corporate E';
    src: url('./assets/fonts/corporate-e-light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Corporate E';
    src: url('./assets/fonts/corporate-e-light-italic.woff2') format('woff2');
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: 'Corporate E';
    src: url('./assets/fonts/corporate-e-regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Corporate E';
    src: url('./assets/fonts/corporate-e-italic.woff2') format('woff2');
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: 'Corporate E';
    src: url('./assets/fonts/corporate-e-medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Corporate E';
    src: url('./assets/fonts/corporate-e-medium-italic.woff2') format('woff2');
    font-weight: 500;
    font-style: italic;
}
@font-face {
    font-family: 'Corporate E';
    src: url('./assets/fonts/corporate-e-bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Corporate E';
    src: url('./assets/fonts/corporate-e-bold-italic.woff2') format('woff2');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Corporate E Demi';
    src: url('./assets/fonts/corporate-e-demi.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Corporate E Demi';
    src: url('./assets/fonts/corporate-e-demi-italic.woff2') format('woff2');
    font-weight: 400;
    font-style: italic;
}

/**
 * Paralucent
 */
@font-face {
    font-family: 'Paralucent';
    src: url('./assets/fonts/paralucent/ParalucentText-Book.woff2') format('woff2'),
        url('./assets/fonts/paralucent/ParalucentText-Book.woff') format('woff'),
        url('./assets/fonts/paralucent/ParalucentText-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Paralucent';
    src: url('./assets/fonts/paralucent/Paralucent-ExtraLight.woff2') format('woff2'),
        url('./assets/fonts/paralucent/Paralucent-ExtraLight.woff') format('woff'),
        url('./assets/fonts/paralucent/Paralucent-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Paralucent';
    src: url('./assets/fonts/paralucent/Paralucent-Medium.woff2') format('woff2'),
        url('./assets/fonts/paralucent/Paralucent-Medium.woff') format('woff'),
        url('./assets/fonts/paralucent/Paralucent-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Paralucent';
    src: url('./assets/fonts/paralucent/Paralucent-Heavy.woff2') format('woff2'),
        url('./assets/fonts/paralucent/Paralucent-Heavy.woff') format('woff'),
        url('./assets/fonts/paralucent/Paralucent-Heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}